import { getListData, getUserData } from "db-utils";
import { useAsync } from "react-use";

export default function useParticipants({ listId }: { listId: string }) {
    const { value, loading, error } = useAsync(async () => {
        const listData = await getListData({ listId });
        if (!listData) return;
        const { participants } = listData;
        const participantsWithUserData = await Promise.all(
            participants.map(async (participant) => {
                const userData = await getUserData({ userId: participant });
                if (!userData || userData === null) return null;
                return {
                    phoneNumber: participant,
                    userId: participant,
                    name: userData.name ?? userData.whatsappDisplayName,
                };
            })
        );

        return participantsWithUserData;
    }, []);

    return { participants: value, loading };
}
