import { ReactNode, createContext, useState } from "react";

export interface Confirm {
    prompt: string;
    isOpen: boolean;
    proceed: () => void;
    cancel: () => void;
}

const initialState: Confirm = {
    prompt: "",
    isOpen: false,
    proceed: () => {},
    cancel: () => {},
};

export const ConfirmContext = createContext<
    [Confirm, React.Dispatch<React.SetStateAction<Confirm>>]
>([initialState, () => {}]);

interface ConfirmContextProviderProps {
    children: ReactNode;
}

const ConfirmContextProvider: React.FC<ConfirmContextProviderProps> = ({
    children,
}) => {
    const [confirm, setConfirm] = useState<Confirm>(initialState);

    return (
        <ConfirmContext.Provider value={[confirm, setConfirm]}>
            {children}
        </ConfirmContext.Provider>
    );
};

export default ConfirmContextProvider;
