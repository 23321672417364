import { Global } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import * as React from "react";

const Root = styled("div")(({ theme }) => ({
    height: "100%",
    backgroundColor:
        theme.palette.mode === "light"
            ? grey[100]
            : theme.palette.background.default,
}));

const Puller = styled("div")(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
}));

export default function Drawer({
    open,
    setOpen,
    children,
    onClose,
    onOpen,
    anchor = "bottom",
    className = "h-auto",
}: {
    open: boolean;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
    onClose: () => void;
    onOpen: () => void;
    className?: string;
    anchor?: "bottom" | "top" | "left" | "right";
}) {
    const iOS =
        typeof navigator !== "undefined" &&
        /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
        <Root>
            <CssBaseline />
            <Global
                styles={{
                    ".MuiDrawer-root > .MuiPaper-root": {
                        overflow: "visible",
                        zIndex: 30,
                    },
                    ".MuiModal-root": {
                        zIndex: "30 !important",
                    },
                }}
            />
            <SwipeableDrawer
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                anchor={anchor}
                open={open}
                onClose={onClose}
                onOpen={onOpen}
                disableSwipeToOpen={false}
            >
                <div
                    dir="rtl"
                    className={`font-heebo bg-white relative px-5 pb-3 ${className}`}
                >
                    {anchor === "bottom" && (
                        <div className="bg-white h-12 absolute -top-10 left-0 right-0 flex justify-center rounded-t-xl">
                            <Puller />
                        </div>
                    )}
                    {children}
                </div>
            </SwipeableDrawer>
        </Root>
    );
}
