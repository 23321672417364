import useListData, { ListDataExtended } from "hooks/useListData";
import { createContext } from "react";

type ListContextType = {
    listData: ListDataExtended | undefined;
    loading: boolean;
    error: unknown;
};

export const ListContext = createContext<ListContextType>({
    listData: undefined,
    loading: false,
    error: null,
});

export default function ListCXT({
    listId,
    children,
}: {
    listId: string;
    children: React.ReactNode;
}) {
    const { listData: value, loading, error } = useListData({ listId });

    // const [listData, setListData] = useState<ListDataExtended | undefined>(
    //     undefined
    // );

    // useEffect(() => {
    //     if (!value) return;
    //     // return;
    //     console.log("value", value);
    //     // setListData({ ...value, id: listId } as ListDataExtended);
    //     const { savedItems, items } = value as ListDataExtended;
    //     console.log("savedItems", savedItems, value);
    //     const updatedItems: Array<Item> = items;

    //     for (const savedItem of savedItems) {
    //         const existingItemIndex = items.findIndex(
    //             (i) => i.text === savedItem.text
    //         );
    //         if (existingItemIndex !== -1) {
    //             const existingItemInList = items[existingItemIndex];
    //             updatedItems[existingItemIndex] = {
    //                 ...existingItemInList,
    //                 saved: true,
    //             };
    //         }
    //     }

    //     const allItemsAreUpToDate = savedItems.every((savedItem) => {
    //         const existingItem = items.find((i) => i.text === savedItem.text);
    //         if (!existingItem) return true;
    //         return existingItem.saved;
    //     });

    //     if (listData && allItemsAreUpToDate) {
    //         console.log("allItemsAreUpToDate");
    //         return;
    //     }

    //     setListData({ ...value, items: updatedItems } as ListDataExtended);
    // }, [value, listData, listId]);

    return (
        <ListContext.Provider value={{ listData: value, loading, error }}>
            {children}
        </ListContext.Provider>
    );
}
