import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { LightningBoltIcon } from "@heroicons/react/solid";
import splitIcon from "assets/images/split icon.png";
import useUser from "hooks/useUser";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { isPremium } from "utils";
import Drawer from "./Drawer";
import Link from "./Link";
import Logo from "./Logo";
import ListActionsMenu, { ListSettingsMenu } from "./list/ListMenu";
import ListProgressBar from "./list/ListProgressBar";

export default function Navbar({
    showOnList = false,
}: {
    showOnList?: boolean;
}) {
    const location = useLocation();
    const isListPage = location.pathname.includes("/list/");

    if (isListPage && !showOnList) return null;

    return (
        <nav
            dir="rtl"
            className="font-heebo fixed z-50 px-3 lg:px-10 py-1 flex justify-between items-center top-0 left-0 h-14 w-screen bg-green-500 shadow-md"
        >
            <Link outsideUserContext to="/">
                <Logo white />
            </Link>
            {/* <SideMenuDrawer buttonStyle="text-white w-7 h-7" /> */}
        </nav>
    );
}

export const ListNavbar = ({
    listProgress,
    isEditing,
    listName,
    showSplitMode = false,
    showActionsMenu = true,
}: {
    listProgress?: number;
    isEditing?: boolean;
    listName: string;
    showSplitMode?: boolean;
    showActionsMenu?: boolean;
}) => {
    const { user } = useUser();

    const isUserPremium = isPremium({
        type: user?.userData?.accountType,
    });

    return (
        <nav
            dir="rtl"
            className="font-heebo fixed z-20 pb-3 pt-6 px-3 flex flex-col gap-5 top-0 left-0 h-24 justify-center w-screen bg-white lg:px-60"
        >
            <div className="flex justify-between items-center w-full">
                <div className="flex gap-3 items-center">
                    <SideMenuDrawer />
                    <h6 className="text-[1.35rem] font-medium">{listName}</h6>
                    {showSplitMode ? (
                        <Link to={`split`}>
                            <img
                                src={splitIcon}
                                alt="split"
                                className="w-5 h-5 mr-2"
                            />
                        </Link>
                    ) : null}
                </div>
                <div className="flex gap-0 items-center">
                    {isUserPremium ? null : (
                        <Link
                            outsideUserContext
                            to={`/premium-info/?phone=${user?.userId}`}
                            className="text-2xl relative"
                        >
                            <LightningBoltIcon className="h-9 p-1 w-9 text-amber-300" />
                            <span className="text-xs text-center w-9 font-semibold absolute -bottom-2.5 left-0">
                                שדרג
                            </span>
                        </Link>
                    )}
                    <ListSettingsMenu />
                    <ListActionsMenu
                        isEditing={isEditing}
                        show={showActionsMenu}
                    />
                </div>
            </div>
            {listProgress !== undefined && !isNaN(listProgress) ? (
                <ListProgressBar progress={listProgress} />
            ) : null}
        </nav>
    );
};

export const SideMenuDrawer = ({
    buttonStyle = "h-7 w-7 text-slate-800",
}: {
    buttonStyle?: string;
}) => {
    const { user } = useUser();

    const [openMenu, setOpenMenu] = useState<boolean>(false);

    if (!user) return null;

    const routes = [
        {
            title: "הרשימות שלי",
            href: `/lists/`,
        },
        {
            title: (
                <span dir="">
                    Groceroo Plus{" "}
                    <LightningBoltIcon className="h-5 w-5 inline-block text-amber-300" />
                </span>
            ),
            href: `/premium-info/?phone=${user.userId}`,
            outsideUserContext: true,
        },
        // {
        //     title: "החשבון שלי",
        //     href: "settings",
        // },
    ];

    const isPremiumUser = isPremium({
        type: user.userData?.accountType,
    });
    return (
        <div className="flex items-center">
            <button onClick={() => setOpenMenu(true)}>
                <MenuIcon className={buttonStyle} />
            </button>
            <Drawer
                open={openMenu}
                setOpen={setOpenMenu}
                onClose={() => setOpenMenu(false)}
                onOpen={() => {}}
                anchor="right"
                className="h-screen w-[80vw] lg:w-[40vw]"
            >
                <div className="w-full pt-3 flex justify-between">
                    <Logo />
                    <button
                        type="button"
                        onClick={() => setOpenMenu(false)}
                        className="flex w-full justify-end p-1"
                    >
                        <XIcon className="h-7 w-7 text-slate-800" />
                    </button>
                </div>
                <div className="flex flex-col justify-between h-[80vh]">
                    <ul className="flex flex-col justify-start gap-0 divide-y divide-slate-300 text-2xl pt-10">
                        {routes.map(({ title, href, outsideUserContext }) => {
                            const isAllHebrewChars =
                                typeof title === "string" &&
                                /^[\u0590-\u05FF\s]+$/.test(title);

                            return (
                                <li
                                    key={title.toString()}
                                    className={`${
                                        isAllHebrewChars ? "" : "font-poppins"
                                    } flex justify-between w-full items-center px-2 py-4`}
                                >
                                    <Link
                                        outsideUserContext={outsideUserContext}
                                        onClick={() => setOpenMenu(false)}
                                        to={href}
                                    >
                                        {title}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                    {isPremiumUser ? (
                        <Link
                            onClick={() => setOpenMenu(false)}
                            to={routes[1].href}
                            className="flex w-48 mx-auto items-center justify-center gap-2 text-lg mt-40 bg-green-400 border-[3px] border-green-600 rounded-full py-1.5 px-2 text-center text-white"
                        >
                            <span>
                                {isPremiumUser ? "משתמש " : "שדרג ל"} Plus{" "}
                                <LightningBoltIcon className="h-5 w-5 inline-block text-amber-300" />
                            </span>
                        </Link>
                    ) : null}
                </div>
            </Drawer>
        </div>
    );
};
