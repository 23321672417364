// import { DocumentData, doc } from "firebase/firestore";
// import { useDocumentData } from "react-firebase-hooks/firestore";
// import { UserData } from "types";
// import { USERS_COLLECTION, db } from "../firebase";

// export type UserDataExtended = UserData & { userId: string };

// export default function useUserData({ userId }: { userId: string }) {
//     const [value, loading, error] = useDocumentData<DocumentData>(
//         doc(db, USERS_COLLECTION, userId)
//     );

//     console.log("useUserData", value, loading, error);
//     if (!value) return { loading, error };

//     return {
//         userData: value
//             ? ({
//                   ...value,
//                   userId,
//               } as UserDataExtended)
//             : undefined,
//         loading,
//         error,
//     };
// }

import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { UserData } from "types";
import { USERS_COLLECTION, db } from "../firebase";

export const useUserData = ({ userId }: { userId: string }) => {
    // const [userData, loading, error] = useDocumentData<DocumentData>(
    //     doc(db, USERS_COLLECTION, userId)
    // );
    const [userData, setUserData] = useState<UserData | undefined | null>(
        undefined
    );
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (typeof userId === "string" && userId.length === 0) {
            setLoading(false);
            setUserData(null);
            return;
        }
        if (!userId) return;

        const unsubscribe = onSnapshot(
            doc(db, USERS_COLLECTION, userId),
            (doc) => {
                setUserData(doc.data() as UserData);
                setLoading(false);
            }
        );

        // Cleanup function to unsubscribe from the snapshot listener when the component unmounts
        return () => unsubscribe();
    }, [userId]);
    return { userData: userData as UserData, loading };
};

export default useUserData;
