import { Dialog, Listbox, Transition } from "@headlessui/react";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { addItemToList } from "db-utils";
import useAlert from "hooks/useAlert";
import useList from "hooks/useList";
import useUser from "hooks/useUser";
import { Fragment, useState } from "react";
import { LIST_TYPES } from "types";
import { CATEGORIES, getCategoryById } from "../../categories";

export default function AddItemPopup() {
    const [isOpen, setIsOpen] = useState(false);
    const { listData } = useList();
    const { user } = useUser();
    const { isAlerted } = useAlert();

    const [formValues, setFormValues] = useState({
        name: "",
        categoryId: CATEGORIES.undefined.id,
    });

    if (!listData || !user || !user?.userId) return null;

    const { items: existingItems, id: listId, type } = listData;
    const { userId } = user;

    const isPrimaryList = type === LIST_TYPES.primary;

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const name = formValues.name.trim();
        if (!name) {
            await isAlerted("שם הפריט לא יכול להיות ריק");
            // alert("שם הפריט לא יכול להיות ריק");
            return;
        }
        const isNewTextNumeric = !isNaN(Number(name));
        if (isNewTextNumeric) {
            await isAlerted("שם הפריט לא יכול להיות ספרה בלבד");
            // alert("שם הפריט לא יכול להיות ספרה בלבד");
            return;
        }
        const isNewTextListWord = name === "רשימה";
        if (isNewTextListWord) {
            await isAlerted('שם הפריט לא יכול להיות "רשימה"');
            // alert('שם הפריט לא יכול להיות "רשימה"');
            return;
        }
        if (existingItems.some((item) => item.text === name)) {
            await isAlerted("פריט עם שם זהה כבר קיים ברשימה");
            // alert("פריט עם שם זהה כבר קיים ברשימה");
            return;
        }

        await addItemToList({
            itemText: name,
            categoryId: formValues.categoryId,
            listId,
            userId,
        });
        closeModal();
    };

    function closeModal() {
        setFormValues({
            name: "",
            categoryId: CATEGORIES.undefined.id,
        });
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    const isSubmitDisabled = !formValues.name;
    // !formValues.name || (isPrimaryList && !formValues.categoryId);

    return (
        <>
            <button
                type="button"
                onClick={openModal}
                className="flex items-center rounded-full justify-center p-2 focus:outline-none h-14 w-14 text-white bg-green-500"
            >
                <PlusIcon className="h-8 w-8" />
            </button>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-30 font-heebo"
                    onClose={closeModal}
                    dir="rtl"
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-slate-900/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-start align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="div"
                                        className="flex justify-between pb-8"
                                    >
                                        <h3 className="text-2xl font-medium leading-6 text-slate-800">
                                            הוספת פריט חדש
                                        </h3>
                                        <button
                                            type="button"
                                            className="focus:outline-none"
                                            onClick={closeModal}
                                        >
                                            <XIcon className="h-6 w-6 text-slate-800" />
                                        </button>
                                    </Dialog.Title>
                                    <form
                                        onSubmit={onSubmit}
                                        className="flex flex-col gap-6"
                                    >
                                        <div>
                                            <label
                                                htmlFor="item-name"
                                                className="block text-xl font-medium text-slate-800"
                                            >
                                                שם הפריט
                                            </label>
                                            <input
                                                type="text"
                                                name="item-name"
                                                id="item-name"
                                                required
                                                value={formValues.name}
                                                className="block w-full text-lg px-2 focus:outline-none shadow-sm h-12 border border-slate-300 mt-1 focus:border-slate-800 rounded-md"
                                                onChange={(e) =>
                                                    setFormValues({
                                                        ...formValues,
                                                        name: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        {isPrimaryList ? (
                                            <CategoriesSelectList
                                                selected={formValues.categoryId}
                                                setSelected={(
                                                    categoryId: string
                                                ) =>
                                                    setFormValues({
                                                        ...formValues,
                                                        categoryId,
                                                    })
                                                }
                                            />
                                        ) : null}

                                        <button
                                            disabled={isSubmitDisabled}
                                            type="submit"
                                            className="mt-3 h-12 inline-flex justify-center py-2 px-4 shadow-sm text-xl font-medium rounded-md text-white bg-green-500 hover:bg-green-600 disabled:bg-slate-300 disabled:cursor-not-allowed"
                                        >
                                            הוספה
                                        </button>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}

const CategoriesSelectList = ({
    selected,
    setSelected,
}: {
    selected: string;
    setSelected: (category: string) => void;
}) => {
    const category = getCategoryById({ id: selected });
    return (
        <div className="">
            <label
                htmlFor="item-name"
                className="block text-xl font-medium text-slate-800"
            >
                קטגוריה
            </label>
            <Listbox value={selected} onChange={setSelected}>
                <div className="relative mt-1">
                    <Listbox.Button className="relative block w-full text-lg px-2 focus:outline-none shadow-sm h-12 border border-slate-300 mt-1 focus:border-slate-800 rounded-md">
                        <div className="flex text-xl font-medium items-center gap-3 w-full py-2 truncate">
                            {category.icon}
                            <span>{category.title}</span>
                        </div>
                        <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                            <ChevronDownIcon
                                className="h-5 w-5 text-slate-800"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="z-40 -top-32 absolute mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-slate-900/5 focus:outline-none">
                            {Object.values(CATEGORIES).map((category) => (
                                <Listbox.Option
                                    key={category.id}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 px-4 ${
                                            active
                                                ? "bg-green-100"
                                                : "text-slate-800"
                                        }`
                                    }
                                    value={category.id}
                                >
                                    {({ selected }) => (
                                        <>
                                            <div key={category.id}>
                                                <div className="flex text-xl font-medium items-center gap-3 w-full py-2">
                                                    {category.icon}
                                                    <span>
                                                        {category.title}
                                                    </span>
                                                </div>
                                            </div>

                                            {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-slate-800">
                                                    <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
};
