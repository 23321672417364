import { logEvent } from "@firebase/analytics";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics } from "../firebase";

export function useFirebaseRoutesAnalytics(data) {
    const location = useLocation();

    useEffect(() => {
        try {
            logEvent(analytics, "screen_view", {
                firebase_screen: location.pathname, // <- In my case I do not want to include search params, so 'location.pathname' is just what I want
                // firebase_screen_class: "firebase-routes-analytics", // <- This name is up to you
                data,
            });
        } catch (err) {
            console.error(err);
        }
    }, [data, location]);

    return null;
}
