import ErrorFallback from "components/ErrorBoundary";
import useUserData from "hooks/useUserData";
import { createContext, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSearchParams } from "react-router-dom";
import { UserData } from "types";
import { decrypt, encrypt } from "../utils";

type UserContextType = {
    user: { userData: UserData | undefined; userId: string | null };
    loading: boolean;
};

export const UserContext = createContext<UserContextType>({
    user: {
        userData: undefined,
        userId: null,
    },
    loading: false,
});

export default function UserCXT({ children }: { children: React.ReactNode }) {
    const [searchParams] = useSearchParams();
    const [userId, setUserId] = useState<string>(
        decrypt(searchParams.get("uid") ?? localStorage.getItem("uid")) ?? ""
    );

    const { userData: value, loading } = useUserData({ userId });
    useEffect(() => {
        if (!userId) return;
        localStorage.setItem("uid", encrypt(userId));
        localStorage.setItem("uid-timestamp", new Date().toISOString());
    }, [userId]);

    // useEffect(() => {
    //     if (userId) return;
    //     const userIdInParams = searchParams.get("uid");
    //     const userIdInLocalStorage = localStorage.getItem("uid");
    //     if (userIdInParams) {
    //         localStorage.setItem("uid", userIdInParams);
    //         setUserId(decrypt(userIdInLocalStorage));
    //         return;
    //     }

    //     if (userIdInLocalStorage) {
    //         setUserId(decrypt(userIdInLocalStorage));
    //     }
    // }, [searchParams, userId]);

    return (
        <ErrorBoundary fallback={<ErrorFallback />}>
            <UserContext.Provider
                value={{ user: { userData: value, userId }, loading }}
            >
                {children}
            </UserContext.Provider>
        </ErrorBoundary>
    );
}

export const useUserId = () => {
    return localStorage.getItem("uid") ?? null;
};
