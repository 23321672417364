import { useAutoAnimate } from "@formkit/auto-animate/react";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import { getCategoryById } from "categories";
import Link from "components/Link";
import LoadingComponents from "components/Loader";
import { ListNavbar } from "components/Navbar";
import PremiumWall from "components/PremiumWall";
import ListCXT from "context/ListContext";
import { updateCategroyOrder } from "db-utils";
import useListData from "hooks/useListData";
import useListIdFromParams from "hooks/useListIdFromParams";
import useUser from "hooks/useUser";

export default function CategoriesOrderPage() {
    const { listId } = useListIdFromParams();

    const [parent] = useAutoAnimate();

    const { listData, loading } = useListData({ listId });
    const { user } = useUser();

    if (loading) return <LoadingComponents fullPage />;
    if (!listData) return null;

    const { userId } = user;
    const { categoriesSortOrder } = listData;

    if (!userId) return null;

    if (!categoriesSortOrder)
        return (
            <div className="flex flex-col items-center justify-center gap-8 mt-20">
                <h1 className="text-2xl text-center">
                    כרגע ניתן לפצל רק את רשימת הקניות הראשית
                </h1>
                <Link
                    to={`/list/${listId}`}
                    className={`px-3 py-2 w-56 text-xl block text-center rounded-lg bg-green-500 text-white hover:bg-green-600`}
                >
                    חזרה לרשימה
                </Link>
            </div>
        );

    // const handleDragEnd = (result: DropResult) => {
    //     if (!listItems) return;
    //     console.log("result", result);
    //     const { source, destination } = result;
    //     if (!destination) return;

    //     const tempTodos = [...listItems];
    //     const [movedItem] = tempTodos.splice(source.index, 1);
    //     console.log("moved", movedItem);
    //     tempTodos.splice(destination?.index, 0, movedItem);
    //     console.log("final one", tempTodos);
    //     setListItems(
    //         tempTodos.map(({ text }, index) => ({ text, sortIndex: index }))
    //     );
    // };

    // if (!listItems) return null;

    const onMove = async ({
        categoryId,
        index,
        moveDirection,
    }: {
        categoryId: string;
        index: number;
        moveDirection: "up" | "down";
    }) => {
        const oldIndex = index;
        const newIndex = moveDirection === "down" ? oldIndex + 1 : oldIndex - 1;
        await updateCategroyOrder({
            listId,
            oldIndex,
            newIndex,
            categeoryToChange: categoryId,
        });
    };

    return (
        // <DragDropContext onDragEnd={handleDragEnd}>
        <PremiumWall title="כאן אפשר לבחור את סדר הקטגוריות ברשימה ולהתאים אותה אישית לסופר שלכם">
            <ListCXT listId={listId}>
                <ListNavbar showActionsMenu={false} listName={`סדר קטגוריות`} />

                {/* <Droppable droppableId="incomplete">
                    {(provided) => {
                        return (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {listItems.map(
                                    ({ text: categoryId }, index) => {
                                        const categoryData = getCategoryById({
                                            id: categoryId,
                                        });
                                        return (
                                            <Draggable
                                                key={categoryId}
                                                draggableId={categoryId}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="list-item-draggable"
                                                    >
                                                        <i className="fa-solid fa-bars"></i>
                                                        <h3>
                                                            {categoryData.title}
                                                        </h3>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    }
                                )}

                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable> */}

                <ul
                    ref={parent}
                    className="flex flex-col divide-y divide-slate-200 text-2xl pt-10 w-full pb-5"
                >
                    {categoriesSortOrder.map((categoryId, index) => {
                        const categoryData = getCategoryById({
                            id: categoryId,
                        });
                        return (
                            <li
                                key={categoryId}
                                className="flex justify-between items-center py-4"
                            >
                                <div className="flex gap-3 items-center">
                                    <span>{categoryData.icon}</span>
                                    {categoryData.title}
                                </div>
                                <div className="flex gap-5 items-center">
                                    <button
                                        onClick={() =>
                                            onMove({
                                                categoryId,
                                                index,
                                                moveDirection: "down",
                                            })
                                        }
                                        disabled={
                                            index ===
                                            categoriesSortOrder.length - 1
                                        }
                                        className="text-red-400 disabled:text-slate-200"
                                    >
                                        <ArrowDownIcon className="w-7 h-7" />
                                    </button>
                                    <button
                                        onClick={() =>
                                            onMove({
                                                categoryId,
                                                index,
                                                moveDirection: "up",
                                            })
                                        }
                                        disabled={index === 0}
                                        className="text-green-400 disabled:text-slate-200"
                                    >
                                        <ArrowUpIcon className="w-7 h-7" />
                                    </button>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </ListCXT>
        </PremiumWall>
        // </DragDropContext>
    );
}
