import { DocumentData, doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { ListData } from "types";
import { LISTS_COLLECTION, db } from "../firebase";

export type ListDataExtended = ListData & { id: string };

export default function useListData({ listId }: { listId: string }) {
    const [value, loading, error] = useDocumentData<DocumentData>(
        doc(db, LISTS_COLLECTION, listId)
    );

    if (!value) return { loading, error };

    return {
        listData: value
            ? ({
                  ...value,
                  id: listId,
              } as ListDataExtended)
            : undefined,
        loading,
        error,
    };
}
