import { ArrowRightIcon, LightningBoltIcon } from "@heroicons/react/outline";
import useUser from "hooks/useUser";
import { decrypt, isPremium } from "utils";
import Link from "./Link";

export default function PremiumWall({
    children,
    title,
}: {
    children: React.ReactNode;
    title?: string;
}) {
    const { user } = useUser();

    if (!user || !user.userData) return null;

    const isPremiumUser = isPremium({
        type: user?.userData?.accountType,
    });

    if (isPremiumUser) {
        return <>{children}</>;
    }

    return (
        <div>
            <div className="flex relative items-start justify-center bg-white -mt-20 h-screen w-screen -mx-4 lg:-mx-60">
                <button
                    className="text-slate-800 absolute right-2 top-2"
                    onClick={() => window.history.back()}
                >
                    <ArrowRightIcon className="w-6 h-6 m-4" />
                </button>
                {/* <div className="absolute z-0 w-full h-full bg-black blur-sm opacity-50"></div> */}
                <div className="relative z-10 w-80 mt-40 h-96 bg-white rounded-xl border-2 border-green-500 shadow-inner flex flex-col justify-between items-center text-center px-6 py-8">
                    <h1 className="text-xl font-medium">
                        אפשרות זו פתוחה רק למשתמשים שבחרו להצטרף לחיים הקלים
                        <LightningBoltIcon className="mr-1 h-5 w-5 inline-block text-amber-300" />
                    </h1>
                    {title}
                    <div className="flex flex-col gap-3 w-10/12">
                        <Link
                            className="text-lg font-medium border-[1.5px] border-slate-700 text-slate-700 rounded-lg shadow-sm px-3 py-2 flex items-center justify-center"
                            to={`/lists/?uid=${decrypt(user?.userId)}`}
                        >
                            מעבר לרשימות שלי
                        </Link>
                        <JoinToPremiumButton userId={user?.userId} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export const JoinToPremiumButton = ({ userId }: { userId: string }) => {
    return (
        <Link
            outsideUserContext
            className="text-lg gap-0.5 font-medium border-[1.5px] border-green-500 bg-green-500 text-white rounded-lg shadow-sm px-3 py-2 flex items-center justify-center"
            to={`/premium-info?phone=${userId}`}
        >
            <span>הצטרפות ל</span>
            <span className="font-poppins">Plus</span>
            <LightningBoltIcon className="mr-1 h-5 w-5 inline-block text-amber-300 fill-amber-300" />
        </Link>
    );
};
