import { Dialog, Transition } from "@headlessui/react";
import Link from "components/Link";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { Fragment, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSearchParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import adsDemo from "../assets/images/features-demo/ads.svg";
import catsOrderDemo from "../assets/images/features-demo/category-order.svg";
import commentsDemo from "../assets/images/features-demo/comments-premium.svg";
import inChatEditDemo from "../assets/images/features-demo/edit-premium.svg";
import multipleListsDemo from "../assets/images/features-demo/multiple-lists.svg";
import noLimitsDemo from "../assets/images/features-demo/no-limits.svg";
import quantitiesDemo from "../assets/images/features-demo/quantities-premiun.svg";
import savedItemsDemo from "../assets/images/features-demo/saved-items.svg";
import splitDemo from "../assets/images/features-demo/split-list.svg";
import typosDemo from "../assets/images/features-demo/typos.svg";
import whoAddedDemo from "../assets/images/features-demo/who-added-that.svg";
import { db } from "../firebase";
import useUserId from "../hooks/useUserId";

const CustomDot = ({ items, ...rest }: { items: any }) => {
    // const CustomDot = ({ onClick, items, ...rest }) => {
    const {
        index,
        active,
        onClick,
        carouselState: { currentSlide, deviceType },
    } = rest as any;
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
        <button
            className={`${
                active ? "bg-slate-800" : "bg-slate-300"
            } mx-2 rounded-full w-2 h-2`}
            onClick={onClick}
        >
            {React.Children.toArray(items)[index]}
        </button>
    );
};

const CustomRightArrow = ({ onClick, ...rest }: { onClick?: any }) => {
    const { userId } = useUserId({});

    const {
        carouselState: { currentSlide, deviceType },
    } = rest as any;
    // onMove means if dragging or swiping in progress.
    return (
        <button
            onClick={() => {
                onClick();
                logEvent("premium-info-next", userId, {
                    currentSlide,
                    deviceType,
                });
            }}
            // onClick={() => onClick()}
            className="text-slate-700 absolute right-0 h-full"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
            </svg>
        </button>
    );
};

const CustomLeftArrow = ({ onClick, ...rest }: { onClick?: any }) => {
    const { userId } = useUserId({});

    const {
        carouselState: { currentSlide, deviceType },
    } = rest as any;
    // onMove means if dragging or swiping in progress.
    return (
        <button
            onClick={() => {
                onClick();
                logEvent("premium-info-prev", userId, {
                    currentSlide,
                    deviceType,
                });
            }}
            className="text-slate-700 absolute left-0 h-full"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5 8.25 12l7.5-7.5"
                />
            </svg>
        </button>
    );
};

export const logEvent = async (
    type: string,
    userId: string | null,
    params?: any
) => {
    try {
        if (userId) {
            const userEventsDocRef = doc(db, "events", userId);

            const data = await getDoc(userEventsDocRef);
            const events = data?.data()?.events ?? [];
            return setDoc(userEventsDocRef, {
                // return setDoc(doc(db, "events", phoneNumber ?? uuid()), {
                events: [...events, { type, params, date: new Date() }],
            });
        }
    } catch (err) {
        console.error(err);
        return setDoc(doc(db, "errors", uuid()), {
            error: JSON.stringify(err),
        });
    }
};

export default function PremiumInfo() {
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const phoneNumber = searchParams.get("phone");
    const { userId } = useUserId({ phoneNumber });

    useEffect(() => {
        logEvent("landing", userId, {});
    }, [userId]);

    const premiumFeatures = [
        {
            title: "3 רשימות נפרדות",
            description:
                "משתמשי Plus מקבלים עוד 2 רשימות נפרדות, שאפשר לעבור בינהן ולנהל במקביל",
            imgSrc: multipleListsDemo,
            imgAlt: "multipleListsDemo",
        },
        {
            title: "פריטים שמורים וקבועים",
            description:
                'קונים את אותם דברים כל שבוע? תוכלו להגדיר חלק מהפריטים ברשימה כ"קבועים" ולהוסיף אותם לרשימה בפקודה אחת',
            imgSrc: savedItemsDemo,
            imgAlt: "savedItemsDemo",
        },
        {
            title: "תמיכה בכמויות",
            description:
                'ניתן לצרף כמויות לפריטים ולנהל את הכמות בקלות, ע"י פקודות מיוחדות שיש רק למשתמשי Plus',
            imgSrc: quantitiesDemo,
            imgAlt: "quantitiesDemo",
        },
        {
            title: "עריכת פריטים בתוך הצ'אט",
            description:
                "ערכו את שם הפריט ישר בתוך השיחה, עם פקודות יעודית במיוחד בשביל זה",
            imgSrc: inChatEditDemo,
            imgAlt: "inChatEditDemo",
        },
        {
            title: "שימוש ללא הגבלה",
            description:
                "ניתן להוסיף כמה משתתפים שרוצים לכל הרשימות, להשתמש בכפתורי השיחה ללא הגבלה ו-100% מהפריטים שלכם מקוטלגים אוטומטית",
            imgSrc: noLimitsDemo,
            imgAlt: "noLimitsDemo",
            withPremiumBadge: true,
        },
        // {
        //     title: "סימון פריט כחסר",
        //     description:
        //         'המוצר חסר בסופר? אין בעיה - פשוט מסמנים אותו כ"חסר" והבוט כבר ידע להתמודד עם זה',
        //     imgSrc: multipleListsDemo,
        //     imgAlt: "multipleListsDemo",
        // },
        {
            title: "בלי פרסומות. בלי לחכות. אף פעם.",
            description:
                'משתמשי Plus לא רואים פרסומות ומקבלים עדיפות בהכנת הרשימות ע"י הבוט, ככה שהם לא צריכים לחכות אף פעם',
            imgSrc: adsDemo,
            imgAlt: "adsDemo",
            withPremiumBadge: true,
        },
        {
            title: "הוספת הערות לפריטים",
            description:
                "ניתן להוסיף הערות לכל פריט, בתוך סוגריים שהבוט יודע להתעלם מהן",
            imgSrc: commentsDemo,
            imgAlt: "commentsDemo",
        },

        {
            title: "חלוקת הרשימה ל-2",
            description:
                "אתם הולכים כזוג לסופר? פצלו את הרשימה שלכם ל-2 וכל אחד יתנהל מול הרשימה שלו, בלי להתנגש ובלי לראות את הפריטים של השני בזמן הקנייה",
            imgSrc: splitDemo,
            imgAlt: "splitDemo",
        },
        {
            title: "מי הוסיף את הדבר הזה?!",
            description:
                'משתמשי Plus יכולים לראות מי הוסיף כל פריט לרשימה שלהם, במקום לצעוק בכל הבית: "מי הוסיף קורנפלקס כריות?!"',
            imgSrc: whoAddedDemo,
            imgAlt: "whoAddedDemo",
        },
        {
            title: "לסדר את הרשימה כמו שאתם רוצים",
            description:
                "תוכלו לשנות את סדר הקטגוריות ברשימה לפי איך שהסופר שלכם בנוי, בהתאמה אישית",
            imgSrc: catsOrderDemo,
            imgAlt: "catsOrderDemo",
        },
        {
            title: "תמיכה בשגיאות כתיב",
            description:
                'כתבתם "מחר" במקום "מחק"? אין בעיה, הבוט מבין מה משתמשי Plus רוצים.\nכן, הוא אפילו יבין שרציתם לסמן את העגבניות כשכתבתם "סמן עגבנייה"',
            imgSrc: typosDemo,
            imgAlt: "typosDemo",
        },
    ];

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const onEarlyPremiumSingup = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        logEvent("premium-singup", userId, {});
        setOpenSuccessPopup(true);
        setIsSubmitted(true);
    };

    return (
        <div className="pb-10 -mt-4" dir="rtl">
            <SuccessSignUpForm
                isOpen={openSuccessPopup}
                setIsOpen={setOpenSuccessPopup}
            />
            <div>
                <h1 className="text-2xl lg:text-5xl text-center underline flex gap-1 items-center justify-center">
                    משתמש Plus{" "}
                    {/* <LightningBoltIcon className="w-6 h-6 text-amber-300" />{" "} */}
                    ⚡ מקבל:
                </h1>
            </div>
            <div className="mt-3">
                <Carousel
                    className="h-full w-full z-10 relative"
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    infinite={true}
                    autoPlay={false}
                    pauseOnHover={true}
                    keyBoardControl={false}
                    transitionDuration={500}
                    responsive={responsive}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                    customDot={
                        <CustomDot
                            items={premiumFeatures.map(({ title }) => (
                                <div className="h-full w-full" />
                            ))}
                        />
                    }
                >
                    {premiumFeatures.map((feature) => (
                        <FeatureCard
                            key={feature.title}
                            title={feature.title}
                            description={feature.description}
                            imgSrc={feature.imgSrc}
                            imgAlt={feature.imgAlt}
                            withPremiumBadge={feature.withPremiumBadge}
                        />
                    ))}
                </Carousel>
            </div>
            <div className="flex flex-col gap-1 justify-center mt-7 lg:w-80 lg:mx-auto">
                <span className="text-center text-sm">
                    הרשמה מוקדמת בעלות{" "}
                    <strong className="font-poppins">₪3.99</strong> לחודש
                </span>
                {/* <span className="text-center text-sm">
                    הרשמה מוקדמת בעלות ₪4 לחודש. אל תדאגו, לא משלמים עכשיו כלום.
                    רק לוחצים על הכפתור וזהו, נרשמתם
                </span> */}
                <Link
                    to={`/checkout/?phone=${phoneNumber}`}
                    // disabled={isSubmitted}
                    // onClick={onEarlyPremiumSingup}
                    className="text-xl px-4 py-2 w-full font-medium text-center rounded-md shadow-sm text-white bg-green-500 disabled:bg-slate-300 disabled:cursor-not-allowed"
                >
                    מגניב! תרשום אותי
                </Link>
            </div>
        </div>
    );
}

const FeatureCard = ({
    title,
    description,
    imgSrc,
    imgAlt,
    withPremiumBadge,
}: {
    title: string;
    description: string;
    imgSrc: string;
    imgAlt: string;
    withPremiumBadge?: boolean;
}) => {
    // const { userId } = useUserId({});

    // useEffect(() => {
    //     logEvent("card-view", userId, { title });
    // }, [userId, title]);

    return (
        <div
            className="flex flex-col gap-2 text-center justify-between"
            dir="rtl"
        >
            <div className="flex flex-col gap-1">
                <h3 className="text-xl font-medium">{title}</h3>
                <p className="text-base ">{description}</p>
            </div>
            <div className="flex h-[47.5vh]  flex-col items-center justify-start gap-2 w-66 mx-auto relative">
                {/* {withPremiumBadge && (
                    <div className="absolute z-10 w-full h-full top-0 left-0 flex items-start pt-[7.4rem] justify-center">
                        <span
                            className="text-xl text-red-500 font-bold rounded-lg py-1 px-2 bg-red-300"
                            style={{
                                transform: "rotateZ(-0deg)",
                            }}
                        >
                            לא יקרה לכם!
                        </span>
                    </div>
                )} */}
                <img
                    className="h-[45vh] w-full max-w-[80vw] mx-auto rounded-lg mb-0"
                    src={imgSrc}
                    alt={imgAlt}
                />
            </div>
        </div>
    );
};

function SuccessSignUpForm({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: any;
}) {
    function closeModal() {
        setIsOpen(false);
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                dir="rtl"
                as="div"
                className="relative z-10 font-heebo"
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-slate-900/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white py-6 px-6 shadow-xl transition-all">
                                <Dialog.Title
                                    as="div"
                                    className="flex flex-col items-center pb-4"
                                >
                                    <button
                                        onClick={closeModal}
                                        className="self-end focus:outline-none"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-5 h-5"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18 18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                    <div className="flex gap-2 items-center justify-center">
                                        {/* <Logo /> */}
                                        <h3 className="font-semibolf text-2xl">
                                            רשמתי אותך
                                        </h3>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-8 h-8 text-green-500"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m4.5 12.75 6 6 9-13.5"
                                            />
                                        </svg>
                                    </div>
                                </Dialog.Title>
                                {
                                    <div className="text-xl">
                                        ניידע אותך כשנתחיל להפעיל את מערכת הPlus
                                        <button
                                            onClick={closeModal}
                                            className="mt-2 text-xl px-4 py-3 w-full font-medium text-center rounded-md shadow-sm text-white bg-green-500"
                                        >
                                            תודה!
                                        </button>
                                    </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
