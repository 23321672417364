import { Timestamp } from "firebase/firestore";

export enum PREMIUM_PAYMENT_PLANS {
    MONTHLY = "monthly",
    MONTHLY3 = "monthly3",
    YEARLY = "yearly",
}

export type UserData = {
    userId: string;
    currentListId: string;
    joinedAt: Date;
    accountType: ACCOUNT_TYPES;
    lists: Array<string>;
    whatsappDisplayName: string;
    name: string;
    messages: Array<MessageRecord>;
    premiumSignup?: Date;
    premiumPaymentPlan?: PREMIUM_PAYMENT_PLANS;
    onboardingProgress: {
        [key: string]: boolean;
    };
    announcements?: {
        [key: string]: { sentDate: Timestamp | null };
    };
    premiumTrialStarted?: Timestamp;
};

export type MessageRecord = {
    text: string;
    type: string;
    timestamp: Timestamp;
    msgId: string;
};

export type UserDetails = {
    name: string;
    phoneNumber: string;
};

export enum ACCOUNT_TYPES {
    free = "free",
    basic = "basic",
    premiumTrial = "premium-trial",
    premium = "premium",
    premiumFamily = "premium-family",
    superPremium = "super-premium",
}

export type PartialListConfig = {
    categories: Array<string>;
    assignees: Array<string>;
};

export type ListData = {
    active: boolean;
    name: string;
    created_at: Date;
    created_by: string;
    participants: Array<string>;
    items: Array<Item>;
    savedItems: Array<Item>;
    type: LIST_TYPES;
    splitMode: boolean;
    partialListsConfig: Array<PartialListConfig> | null;
    partOfPremium: boolean; // at least one of the participants is premium
    categoriesSortOrder: Array<string>;
    changedSplitOrder?: boolean;
};

export enum LIST_TYPES {
    primary = "primary",
    secondary = "secondary",
}

export enum ADDED_FROM {
    whatsApp = "whatsApp",
    webapp = "webapp",
}

export type Item = {
    text: string;
    originalText: string;
    categoryId: string;
    addedAt: Timestamp;
    // addedAt: { seconds: number; milliseconds: number };
    addedBy: string;
    addedFrom: ADDED_FROM;
    quantity: number | null;
    unit: string | null;
    comments: string | null;
    checked: boolean;
    missing: boolean;
    saved: boolean;
};
