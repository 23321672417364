import { useAutoAnimate } from "@formkit/auto-animate/react";
import { XIcon } from "@heroicons/react/outline";
import LoadingComponents from "components/Loader";
import { ListNavbar } from "components/Navbar";
import ListCXT from "context/ListContext";
import { removeParticipantFromList } from "db-utils";
import useConfirm from "hooks/useConfirm";
import useList from "hooks/useList";
import useListData from "hooks/useListData";
import useListIdFromParams from "hooks/useListIdFromParams";
import useParticipants from "hooks/useParticipants";
import useUser from "hooks/useUser";
import { formatPhoneNumber } from "utils";

export default function ListParticipantsPage() {
    const { listId } = useListIdFromParams();
    const [parent] = useAutoAnimate();

    const { listData } = useListData({ listId });
    const { user } = useUser();
    const { participants, loading } = useParticipants({ listId });

    if (loading) return <LoadingComponents fullPage />;
    if (!listData || !participants) return null;

    const { userId } = user;
    const { created_by: createdBy } = listData;

    if (!userId) return null;

    const isUserInList = participants.some(
        (participant) => participant !== null && participant.userId === userId
    );

    if (!isUserInList) return <div>אתם לא נמצאים ברשימה הזו</div>;

    return (
        <ListCXT listId={listId}>
            <ListNavbar
                listName={`מי ברשימה - ${listData.name}`}
                showActionsMenu={false}
            />
            <ul
                ref={parent}
                className="flex flex-col divide-y divide-slate-200 text-2xl mt-5 w-full py-5"
            >
                {participants.length > 0 ? (
                    participants.map((participant) =>
                        participant === null ? null : (
                            <li key={participant.userId}>
                                <ParticipantItem
                                    name={participant.name}
                                    participantId={participant.userId}
                                    phoneNumber={participant.phoneNumber}
                                    isListOwner={userId === createdBy}
                                    userId={userId}
                                />
                            </li>
                        )
                    )
                ) : (
                    <div className="grid place-content-center text-2xl">
                        אין משתמשים להראות
                    </div>
                )}
            </ul>
        </ListCXT>
    );
}

const ParticipantItem = ({
    name,
    participantId,
    phoneNumber,
    isListOwner,
    userId,
}: {
    name: string;
    participantId: string;
    phoneNumber: string;
    isListOwner: boolean;
    userId: string;
}) => {
    const { listData } = useList();

    const { isConfirmed } = useConfirm();

    if (!listData) return null;

    const { id: listId, type: listType } = listData;

    const removeParticipant = async () => {
        if (
            await isConfirmed(`האם אתה בטוח שברצונך להסיר את ${name} מהרשימה?`)
        ) {
            await removeParticipantFromList({
                listId,
                participantId,
                listType,
            });
            window.location.reload(); // reload to update the list
        }
    };

    const userIsCurrentParticipant = participantId === userId;
    const canRemoveHimself = userIsCurrentParticipant && !isListOwner; // if it's the owner primary list, block
    const canRemoveOthers = !userIsCurrentParticipant && isListOwner; // if owner and not current user, allow
    const canRemoveParticipant = canRemoveHimself || canRemoveOthers;

    return (
        <div className="flex gap-3 items-center justify-between w-full text-lg py-5 px-1 border-t">
            {name} - {formatPhoneNumber({ phoneNumber })}{" "}
            {isListOwner && userIsCurrentParticipant ? "(בעלים)" : null}
            <button
                disabled={!canRemoveParticipant}
                onClick={removeParticipant}
                className="group"
            >
                <XIcon className="h-7 w-7 text-red-500 group-disabled:text-slate-200" />
            </button>
        </div>
    );
};
