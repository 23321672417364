import { categoriesIdsOrdered } from "categories";

export const BOT_PHONE_NUMBER = "972559733562";
// export const BOT_PHONE_NUMBER = "447930966794";

export const registerToBotURL = `https://wa.me/${BOT_PHONE_NUMBER}?text=הרשמה`;

export const registerToSharedListURL = `https://wa.me/${BOT_PHONE_NUMBER}?text=הרשמה `;

export const WEBAPP_URL = "https://groceroo.app";
// export const WEBAPP_URL = "https://groceries-bot-473f2--test-ypnp62rj.web.app";

export const mobileWhatsAppURL = `whatsapp://send?text=`;
export const desktopWhatsAppURL = `https://wa.me/?text=`;

export const inviteToSharedListURLDesktop = `https://wa.me/?text=בואו תצטרפו לרשימה שלי!
מצטרפים כאן:
${WEBAPP_URL}/join/`;

export const inviteToSharedListURLMobile = `whatsapp://send?text=בואו תצטרפו לרשימה שלי!
מצטרפים כאן:
${WEBAPP_URL}/join/`;

const SHARE_GROCEROO_TEXT = `להצטרפות ל *Groceroo* - בוט רשימת הקניות:\n${WEBAPP_URL}/join/`;

export const shareGrocerooURLDesktop = `https://wa.me/?text=${SHARE_GROCEROO_TEXT}`;

export const sharedGrocerooURLMobile = `whatsapp://send?text=${SHARE_GROCEROO_TEXT}`;

export const DEFAULT_CATEGORIES_ORDER = categoriesIdsOrdered;
export const PRIMARY_LIST_NAME = "קניות לסופר";

export const encryptionKey = 2;

export const unitsConfig: {
    [key: string]: {
        keywords: string[];
        displayText: string;
        shortText: string;
    };
} = {
    kilo: {
        keywords: ["קילו", 'ק"ג', "קג", "ק'ג", "קילוגרם"],
        displayText: 'ק"ג',
        shortText: 'ק"ג',
    },
    gram: {
        keywords: ["גרם", "ג'", "ג"],
        displayText: "גרם",
        shortText: "ג'",
    },
    liter: {
        keywords: ["ליטר", "ל'"],
        displayText: "ליטר",
        shortText: "ל'",
    },
    mililiter: {
        keywords: ["מיליליטר", 'מ"ל'],
        displayText: 'מ"ל',
        shortText: 'מ"ל',
    },
    miligram: {
        keywords: ["מיליגרם", 'מ"ג'],
        displayText: 'מ"ג',
        shortText: 'מ"ג',
    },
    package: {
        keywords: ["חבילות", "חבילה", "חבילת"],
        displayText: "חבילות",
        shortText: "",
    },
    unit: {
        keywords: ["יחידות", "יח'", "יח"],
        displayText: "יח'",
        shortText: "יח'",
    },
};
