import { Item } from "./types";

export type Category = {
    id: string;
    title: string;
    keywords?: Array<string>;
    icon: string;
};

export const CATEGORIES: Record<string, Category> = {
    VEGETABLES: {
        id: "vegetables",
        title: "ירקות",
        icon: "🥬",
    },
    FRUITS: {
        id: "fruits",
        title: "פירות",
        icon: "🍎",
    },
    MEAT: {
        id: "meat",
        title: "בשר ועוף",
        icon: "🥩",
    },
    DAIRY: {
        id: "dairy",
        title: "מוצרי חלב",
        icon: "🧀",
    },
    EGGS: {
        id: "eggs",
        title: "ביצים",
        icon: "🥚",
    },
    BAKERY: {
        id: "bakery",
        title: "לחם ומאפים",
        icon: "🥖",
    },
    GRAINS_AND_PASTA: {
        id: "grains_and_pasta",
        title: "פסטה ודגנים",
        icon: "🍝",
    },
    DRINKS: {
        id: "drinks",
        title: "משקאות",
        icon: "🥤",
    },
    SEAFOOD: {
        id: "seafood",
        title: "דגים",
        icon: "🐟",
    },
    SPICES: {
        id: "spices",
        title: "תבלינים",
        icon: "🧂",
    },
    SNACKS: {
        id: "snacks",
        title: "חטיפים",
        icon: "🍫",
    },
    SUASAUGES_AND_DELI: {
        id: "sausages_and_deli",
        title: "נקניקים ומעדנייה",
        icon: "🌭",
    },
    COFFEE_AND_TEA: {
        id: "coffee_and_tea",
        title: "קפה ותה",
        icon: "☕",
    },
    CANS_AND_JARS: {
        id: "cans_and_jars",
        title: "שימורים וצנצנות",
        icon: "🥫",
    },
    WINE_AND_ALCOHOL: {
        id: "wine_and_alcohol",
        title: "יין ואלכוהול",
        icon: "🍷",
    },
    NUTS_AND_SEEDS: {
        id: "nuts_and_seeds",
        title: "אגוזים וזרעים",
        icon: "🥜",
    },
    CEREAL_AND_MUSSLI: {
        id: "cereal_and_mussli",
        title: "קורנפלקס וגרנולה",
        icon: "🥣",
    },
    SAUCES_AND_DRESSINGS: {
        id: "sauces_and_dressings",
        title: "רטבים וממרחים",
        icon: "🍯",
    },
    BABY: {
        id: "baby",
        title: "מוצרי תינוקות",
        icon: "👶",
    },
    FROZEN: {
        id: "frozen",
        title: "קפואים",
        icon: "❄️",
    },
    CLEANING: {
        id: "cleaning",
        title: "טואלטיקה",
        icon: "🧻",
    },
    BATH: {
        id: "bath",
        title: "מוצרי רחצה",
        icon: "🛀",
    },
    DISPOSABLE: {
        id: "disposable",
        title: "כלים חד פעמיים",
        icon: "🍽",
    },
    BY_THE_COUNTER: {
        id: "by_the_counter",
        title: "ליד הקופה",
        icon: "🔋",
    },
    undefined: { id: "others", title: "שונות", icon: "🏷" },
};

export const questionMarkEmoji = "❓";

export const categoriesIdsOrdered = Object.values(CATEGORIES).map(
    ({ id }) => id
);

export const sortItemsByCategory = ({
    items,
    categoriesSortOrder,
}: {
    items: Array<Item>;
    categoriesSortOrder?: Array<string>;
}): Array<Item> => {
    if (!categoriesSortOrder || categoriesSortOrder.length === 0) {
        return items;
    }

    const updatedCategoriesSortOrder = categoriesSortOrder;

    const missingCategories = categoriesIdsOrdered.filter(
        (categoryId) => !categoriesSortOrder.includes(categoryId)
    );

    if (missingCategories) {
        // put in the one-before-last place
        const index = updatedCategoriesSortOrder.length - 1;
        updatedCategoriesSortOrder.splice(index, 0, ...missingCategories);
    }

    const itemsByCategory = items.reduce(
        (acc: { [key: string]: Array<Item> }, item) => {
            const { categoryId } = item;
            if (!acc[categoryId]) {
                acc[categoryId] = [];
            }
            acc[categoryId].push(item);
            return acc;
        },
        {}
    );

    const sortedItems = updatedCategoriesSortOrder.reduce(
        (acc: Array<Item>, categoryId) => {
            // this will not put items with new category that is not in the categoriesSortOrder of the user
            if (itemsByCategory[categoryId]) {
                acc = acc.concat(itemsByCategory[categoryId]);
            }
            return acc;
        },
        []
    );

    return sortedItems;
};

export const getCategoryById = ({ id }: { id: string }) => {
    return (
        Object.values(CATEGORIES).find((category) => category.id === id) ??
        CATEGORIES.undefined
    );
};
