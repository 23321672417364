import {
    ArrowRightIcon,
    BookmarkIcon,
    CheckIcon,
    MinusIcon,
    PlusIcon,
} from "@heroicons/react/outline";
import { getCategoryById } from "categories";
import CategoriesDropdown from "components/CategoriesDropdown";
import Drawer from "components/Drawer";
import TrashIcon from "components/icons/TrashIcon";
import { deleteItemFromDB, updateItemInDB } from "db-utils";
import useAlert from "hooks/useAlert";
import useConfirm from "hooks/useConfirm";
import useList from "hooks/useList";
import useUser from "hooks/useUser";
import { useEffect, useRef, useState } from "react";
import { Item, LIST_TYPES } from "types";
import { formatPhoneNumber, getUnitText, isPremium } from "utils";
import { unitsConfig } from "../../constants";

export default function EditItemDrawer({
    open,
    setOpen,
    onClose,
    onOpen,
    text,
    quantity,
    unit,
    categoryId,
    comments,
    checked,
    addedBy,
    addedAt,
    saved,
}: Item & {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
    onOpen: () => void;
}) {
    const { listData, loading, error } = useList();
    const { user } = useUser();
    const [values, setValues] = useState({
        text,
        quantity,
        unit,
        categoryId,
        comments,
        saved,
        checked,
    });

    useEffect(() => {
        setValues({
            text,
            quantity,
            unit,
            categoryId,
            comments,
            saved,
            checked,
        });
    }, [text, quantity, unit, categoryId, comments, saved, checked]);

    const nameInputRef = useRef<HTMLInputElement>(null);

    // useEffect(() => {
    //     if (open) {
    //         nameInputRef.current?.focus();
    //     }
    // }, [open]);

    const { isConfirmed } = useConfirm();
    const { isAlerted } = useAlert();
    const isPremiumUser = isPremium({
        type: user?.userData?.accountType,
    });

    if (!listData) return null;

    const PremiumWallAlert = () =>
        isAlerted(
            "רק משתמשי Plus ⚡ יכולים להשתמש בפי'צר הזה. מוזמנים להצטרף אליהם"
        );

    const changeQuantity = async ({ type }: { type: "inc" | "dec" }) => {
        if (!isPremiumUser) {
            await PremiumWallAlert();
            return;
        }
        const newQuantity = (values.quantity ?? 0) + (type === "inc" ? 1 : -1);
        setValues({
            ...values,
            quantity: newQuantity <= 1 ? 1 : newQuantity,
        });
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!listData || !listData.items) {
            await isAlerted("מצטערים, קרתה תקלה");
            // alert("מצטערים, קרתה תקלה");
            return;
        }

        const listWithoutCurrentItem = listData.items.filter(
            (item) => item.text !== text
        );
        const itemName = values.text;
        if (listWithoutCurrentItem.map((i) => i.text).includes(itemName)) {
            await isAlerted("מצטערים, כבר קיים פריט עם השם הזה");
            // alert("מצטערים, כבר קיים פריט עם השם הזה");
            return;
        }

        const updates = {
            text: values.text.trim(),
            quantity: values.quantity,
            unit: values.unit,
            categoryId: values.categoryId,
            comments: values.comments ? values.comments.trim() : null,
            saved: values.saved,
            checked: values.checked,
        };

        await updateItemInDB({
            itemId: text,
            listId: listData.id,
            existingItems: listData.items,
            existingSavedItems: listData.savedItems,
            updates,
        });
        onClose();
    };

    const onDelete = async () => {
        if (await isConfirmed("בטוח להמשיך עם המחיקה?")) {
            await deleteItemFromDB({
                itemId: text,
                listId: listData.id,
                existingItems: listData.items,
            });
            setOpen(false);
        }
    };

    const addedAtDate = new Date(addedAt.seconds * 1000).toLocaleDateString(
        "he-IL",
        {
            dateStyle: "short",
        }
    );

    const { type } = listData;
    const isPrimaryList = type === LIST_TYPES.primary;

    const unitOptions = [
        values.unit ? (
            <option key={values.unit} value={values.unit}>
                {getUnitText(values.unit)}
            </option>
        ) : (
            <option key="none" value={undefined}>
                יחידה
            </option>
        ),
    ].concat(
        Object.entries(unitsConfig)
            .filter(([u, _]) => u !== values.unit)
            .map(([key, value]) => (
                <option key={key} value={key}>
                    {value.displayText}
                </option>
            ))
    );

    return (
        <Drawer
            className="flex flex-col justify-between pb-5 h-[75vh]"
            open={open}
            setOpen={setOpen}
            onClose={() => setOpen(false)}
            onOpen={onOpen}
        >
            <form
                className="text-xl flex flex-col h-full justify-between pt-5"
                onSubmit={onSubmit}
            >
                <div className="text-xl flex flex-col gap-7">
                    <div className="flex justify-between items-center gap-3">
                        <button type="button" onClick={() => setOpen(false)}>
                            <ArrowRightIcon className="w-7 h-7" />
                        </button>
                        <input
                            ref={nameInputRef}
                            type="text"
                            value={values.text ?? ""}
                            onChange={(e) =>
                                setValues({ ...values, text: e.target.value })
                            }
                            placeholder="שם הפריט"
                            className="w-full h-12 px-2 rounded-md border border-gray-300 placeholder-gray-400 focus:outline-none text-slate-800 focus:border-slate-900"
                        />
                        {isPrimaryList ? (
                            <CategoriesDropdown
                                button={
                                    <div className="bg-slate-300/20 rounded-full w-10 h-10 text-2xl flex items-center justify-center transition-all">
                                        {
                                            getCategoryById({
                                                id: values.categoryId,
                                            }).icon
                                        }
                                    </div>
                                }
                                onSelect={(categoryId) =>
                                    setValues({
                                        ...values,
                                        categoryId,
                                    })
                                }
                            />
                        ) : null}
                    </div>
                    <div className="flex gap-2 justify-between items-center">
                        <input
                            type="number"
                            value={values.quantity ?? ""}
                            onChange={async (e) => {
                                if (!isPremiumUser) {
                                    await PremiumWallAlert();
                                    return;
                                }
                                const newQuantity = Number(e.target.value);
                                setValues({
                                    ...values,
                                    quantity:
                                        newQuantity <= 0 ? null : newQuantity,
                                });
                            }}
                            className="w-1/3 h-12  px-2 rounded-md border border-gray-300 placeholder-gray-400 focus:outline-none text-slate-800 focus:border-slate-900"
                            placeholder="כמות"
                        />
                        <select
                            value={values.unit ?? ""}
                            onChange={async (e) => {
                                if (!isPremiumUser) {
                                    await PremiumWallAlert();
                                    return;
                                }
                                setValues({
                                    ...values,
                                    unit: e.target.value ?? null,
                                });
                            }}
                            className="h-12 w-1/3 px-2 rounded-md border border-gray-300 placeholder-gray-400 focus:outline-none text-slate-800 focus:border-slate-900"
                            placeholder="יחידה"
                        >
                            {unitOptions.map((option) => option)}
                        </select>

                        <div className="flex gap-3 justify-between w-1/3">
                            <button
                                disabled={Boolean(
                                    values.quantity && values.quantity > 100
                                )}
                                onClick={() => changeQuantity({ type: "inc" })}
                                type="button"
                                className="flex items-center justify-center h-12 w-12 rounded-full text-green-500 disabled:text-slate-200"
                            >
                                <PlusIcon className="w-8 h-8" />
                            </button>
                            <button
                                type="button"
                                disabled={Boolean(
                                    !values.quantity || values.quantity <= 1
                                )}
                                onClick={() => changeQuantity({ type: "dec" })}
                                className="flex items-center justify-center h-12 w-12 rounded-full text-green-500 disabled:text-slate-200"
                            >
                                <MinusIcon className="w-8 h-8" />
                            </button>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <span>שמור כקבוע:</span>
                        <button
                            type="button"
                            onClick={async () => {
                                if (!isPremiumUser) {
                                    await PremiumWallAlert();
                                    return;
                                }
                                setValues({ ...values, saved: !values.saved });
                            }}
                            className={`transition-all duration-200`}
                        >
                            {values.saved ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-8 h-8 text-amber-300"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M6.32 2.577a49.255 49.255 0 0 1 11.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 0 1-1.085.67L12 18.089l-7.165 3.583A.75.75 0 0 1 3.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93Z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            ) : (
                                <BookmarkIcon className="text-slate-800 h-8 w-8" />
                            )}
                        </button>
                        {/* <Switch
                            checked={values.saved}
                            onChange={(checked) => {
                                setValues({ ...values, saved: checked });
                            }}
                            className={`${
                                values.saved ? "bg-green-500" : "bg-slate-300"
                            } relative justify-end inline-flex h-8 items-center w-16 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                        >
                            <span className="sr-only">save items</span>
                            <span
                                aria-hidden="true"
                                className={`${
                                    values.saved
                                        ? "translate-x-8"
                                        : "translate-x-0"
                                } pointer-events-none inline-block h-7 w-7 transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out`}
                            />
                        </Switch> */}
                    </div>
                    <div className="flex flex-col gap-1">
                        <label htmlFor="comments" className="text-sm h-4">
                            {values.comments ? "הערה:" : null}
                        </label>
                        <input
                            type="text"
                            disabled={!isPremiumUser}
                            id="comments"
                            value={values.comments ?? ""}
                            onChange={(e) =>
                                setValues({
                                    ...values,
                                    comments: e.target.value,
                                })
                            }
                            placeholder={
                                isPremiumUser
                                    ? "כאן כותבים הערה"
                                    : "הערה (למשתמשי Plus בלבד)"
                            }
                            className="h-12 w-full px-2 rounded-md border border-gray-300 placeholder-gray-400 focus:outline-none text-slate-800 focus:border-slate-900"
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-4 pt-4">
                    <button
                        onClick={onDelete}
                        type="button"
                        className="w-full text-xl px-4 flex items-center justify-center text-center gap-2 text-red-400 py-3 rounded-md shadow-sm border-red-500 border-2 "
                    >
                        <TrashIcon className="w-7 h-7" />
                        <span>מחיקה</span>
                    </button>
                    <button
                        type="submit"
                        className="w-full text-xl px-4 flex items-center justify-center text-center gap-2 text-white py-3 rounded-md shadow-sm bg-green-500 border-green-500"
                    >
                        <CheckIcon className="w-7 h-7" />
                        <span>שמירה</span>
                    </button>
                    {addedBy ? (
                        <div className="text-sm text-center">
                            נוסף ע"י{" "}
                            <span className="font-bold">
                                {formatPhoneNumber({ phoneNumber: addedBy })}
                            </span>{" "}
                            בתאריך <span className="">{addedAtDate}</span>
                        </div>
                    ) : null}
                </div>
            </form>
        </Drawer>
    );
}
