export default function TermsOfService() {
    return (
        <div className="flex flex-col gap-5 my-4">
            <h1 className="text-center text-3xl">
                תנאי שימוש באתר, באפליקציית ובשירות "Groceroo"
            </h1>
            <h3 className="text-lg py-3">
                תקנון השימוש באתר הנ"ל נכתב בלשון זכר אך האמור בו מתייחס לנשים
                וגברים כאחד.
            </h3>
            <p></p>
            <ol>
                <li>
                    <h4 className="text-xl py-3">קדימון</h4>
                    <p>
                        אתר "Groceroo" (להלן "האתר") הוא אתר המשתמש כאתר (רשימת
                        קניות בתוך הוואטסאפ) ייצוגי עבור "Groceroo" והנך מוזמן
                        לקחת בו חלק בכפוף להסכמתך לתנאי השימוש אשר יפורטו להלן
                    </p>
                    <p>האתר, מוצע למשתמש כמות שהוא&nbsp;(‘As Is’).</p>
                    <p>
                        בנוסף השימוש באתר זה על כל תכניו והשירותים המוצעים בו,
                        הורדות של קבצים, מדיה כגון תמונות וסרטונים והתכנים
                        השונים המוצעים לקהל המבקרים עשויים להשתנות מעת לעת או
                        בהתאם לסוג התוכן.
                    </p>
                    <p>
                        הנהלת האתר שומרת לעצמה הזכות לעדכן את תנאי השימוש
                        המוצגים להלן מעת לעת וללא התראה או אזכור מיוחד בערוצי
                        האתר השונים.
                    </p>
                </li>
                <li>
                    <h4 className="text-xl py-3">קניין רוחני</h4>
                    <p>
                        האתר כמו גם כל המידע שבו לרבות עיצוב האתר, קוד האתר,
                        קבצי מדיה לרבות גרפיקות, סרטונים, תמונות, טקסטים, קבצים
                        המוצעים להורדה וכל חומר אחר אשר מוצג באתר שייכים במלואם
                        לאתר הנ"ל ומהווים קניין רוחני בלעדי של אתר "Groceroo"
                        ואין לעשות בהם שימוש ללא אישור כתוב מראש מאתר
                        "Groceroo".
                    </p>
                    <p>
                        בנוסף אין להפיץ, להעתיק, לשכפל, לפרסם, לחקות או לעבד
                        פיסות קוד, גרפיקות, סרטונים, סימנים מסחריים או כל מדיה
                        ותוכן אחר מבלי שיש ברשותכם אישור כתוב מראש.
                    </p>
                </li>
                <li>
                    <h4 className="text-xl py-3">תוכן האתר</h4>
                    <p>
                        אנו שואפים לספק לכם את המידע המוצג באתר ללא הפרעות אך
                        יתכנו בשל שיקולים טכניים, תקלות צד ג או אחרים, הפרעות
                        בזמינות האתר. ולכן איננו יכולים להתחייב כי האתר יהיה
                        זמין לכם בכל עת ולא יינתן כל פיצוי כספי או אחר בשל הפסקת
                        השירות / הורדת האתר.
                    </p>
                    <p>
                        קישורים לאתר חיצוניים אינם מהווים ערובה כי מדובר באתרים
                        בטוחים, איכותיים או אמינים וביקור בהם נעשה על דעתכם
                        האישית בלבד ונמצאים בתחום האחריות הבלעדי של המשתמש באתר.
                    </p>
                    <p>
                        התכנים המוצעים באתר הינם בבעלותם הבלעדית של "Groceroo"
                        ואין לעשות בהם שימוש אשר נועד את האמור בתקנון זה (ראה
                        סעיף 3) למעט במקרים בהם צוין אחרת או במקרים בהם צוין כי
                        זכויות היוצרים שייכים לגוף חיצוני. במקרים אלו יש לבדוק
                        מהם תנאי השימוש בקישור המצורף ולפעול על פי המצוין באתר
                        החיצוני לו שייכים התכנים.
                    </p>
                </li>
                <li>
                    <h4 className="text-xl py-3">ניהול משתמשים ומבקרים באתר</h4>
                    <p>
                        הנהלת האתר שומרת לעצמה את הזכות לחסום כל משתמש ובין אם
                        על ידי חסימת כתובת הIP של המחשב שלו, כתובת הMACID של
                        המחשב שלו או אפילו בהתאם למדינת המוצא ללא צורך לספק
                        תירוץ אשר מקובל על הגולש.
                    </p>
                    <p>
                        צוות האתר / הנהלת האתר יעשה כל שביכולתו להגן על פרטי
                        המשתמשים הרשומים באתר / מנויים הרשומים באתר. במקרים בהם
                        יעלה בידיו של צד שלישי להשיג גישה למידע מוסכם בזאת כי
                        לגולשים, משתמשים וחברים באתר לה תהה כל תביעה, טענה או
                        דרישה כלפי צוות האתר "Groceroo".
                    </p>
                </li>
                <li>
                    <h4 className="text-xl py-3">גילוי נאות</h4>
                    <p>
                        באתר זה עשוי לעשות שימוש בקבצי קוקיז (במיוחד עבור
                        משתמשים רשומים ומנויים) ובממשקי סטטיסטיקה פנימיים בכדי
                        לשמור תיעוד סטטיסטי אנונימי של גולשים וניתוח תנועת
                        הגולש/ים, הרגלי גלישה באתר וניתוח קליקים וזמן שהייה.
                    </p>
                    <p>
                        בכל העת ולבד מאשר גולשים המחוברים לאתר המידע הנשמר הוא
                        אנונימי לחלוטין ואין בו את שם הגולש או כל פרט מזהה אחר.
                    </p>
                </li>
                <li>
                    <h4 className="text-xl py-3">איזור שיפוט</h4>
                    <p>
                        בעת שאתם עושים שימוש באתר ובמקרה בו התגלעה כל מחולקת אתם
                        מסכימים להלן כי האמור לעיל נמצא תחת סמכות השיפוט הבלעדי
                        של החוק הישראלי תוך שימוש במערכת בתי המשפט הישראליים
                        בלבד במחוז תל אביב.
                    </p>
                </li>
            </ol>
        </div>
    );
    // return (
    //     <div>
    //         <div className="flex flex-col gap-5 my-4">
    //             <h1 className="text-center text-3xl">תנאי שימוש</h1>
    //             <h2 className="text-2xl">כללי</h2>
    //             <p>
    //                 {" "}
    //                 1. אתר האינטרנט הוא בבעלות של חברת “Groceroo” (להלן:
    //                 "החברה"). <br /> 2. תנאי שימוש אלו יחולו על כל התחברות ו/או
    //                 גלישה באתר, על ידי המשתמשים, ועשויים להשתנות מעת לעת, על פי
    //                 שיקול דעתה הבלעדי של החברה. <br /> 3. טרם כניסה/גלישה באתר
    //                 נדרש המשתמש לקרוא, בעיון רב, את תנאי השימוש. כניסה לאתר
    //                 מהווה הסכמה לתנאי השימוש. <br /> 4. מובהר בזאת, כי השימוש
    //                 בלשון זכר בתנאי השימוש, נעשה מטעמי נוחות בלבד ואין בו כדי
    //                 לפגוע ו/או ליצור אפליה כלשהי. <br /> 5. כניסה/גלישה באתר
    //                 מהווה אישור כי המשתמש קרא ומסכים לתנאי השימוש ללא כל הגבלה
    //                 או הסתייגות.{" "}
    //             </p>
    //             <h2 className="text-2xl">הגבלת האחריות</h2>
    //             <p>
    //                 {" "}
    //                 1. האתר, מוצע למשתמש כמות שהוא&nbsp;(‘As Is’) <br /> 2.
    //                 החברה אינה מציגה כל מצג ואינה אחראית בכל אחריות מפורשת או
    //                 משתמעת בקשר עם האתר והתכנים המועלים בו, וכן בקשר עם התאמתו
    //                 למטרה ספציפית ו/או לדרישות המשתמש. <br /> 3. כל שימוש באתר
    //                 יתבצע על אחריותו הבלעדית של המשתמש. למשתמש לא תהיה כל טענה
    //                 ו/או תביעה כנגד החברה בקשר לאתר ו/או שימושו בו ו/או שימושו
    //                 של צד שלישי, בגין כל נזק ישיר או עקיף, הנובע משימוש ו/או בשל
    //                 הסתמכות על תוכן של צד שלישי ו/או בשל פגיעה בפרטיות ו/או
    //                 פגיעה אחרת בעקבות שימוש כאמור. <br /> 4. החברה ו/או מי
    //                 מטעמה, לא יישאו בכל אחריות שהיא, לנזק ו/או אובדן, ישיר או
    //                 עקיף, לרבות נזק נלווה, תוצאתי, אקראי או פיצויים עונשיים
    //                 (ובכלל זה, ומבלי לגרוע מכלליות האמור, פיצויים בגין הפסד
    //                 עבודה ועסקים, הפסד רווחים, הפסקות והפרעות עבודה, הפסד ו/או
    //                 איבוד מידע עסקי, פגיעה במוניטין, וכל הפסד ו/או נזק כספי אחר,
    //                 צפוי ושאינו צפוי), הנובעים ו/או הקשורים, באתר, או בכל שימוש
    //                 בו, <br /> 5. החברה אינה אחראית לכל פעילות בלתי חוקית
    //                 שתתבצע, ככל שתתבצע, על ידי מי מהמשתמשים באתר ו/או כל גורם
    //                 אחר ברשת האינטרנט שאין לה שליטה עליו. <br /> 6. בלי למעט
    //                 מהאמור, המשתמש אינו רשאי: <br /> 6.1 לעשות שימוש מסחרי באתר
    //                 ו/או באמצעותו. <br /> 6.2 לשבש או להפר כל זכות של משתמש אחר
    //                 באתר ו/או ברשת האינטרנט, לרבות הזכות לפרטיות ו/או לאסוף מידע
    //                 אישי על המשתמשים באתר, לרבות באמצעים אוטומטיים. <br /> 6.3
    //                 לפגוע בכבודו או בפרטיותו של משתמש אחר ו/או להשתמש באתר כדי
    //                 לפגוע בשמו הטוב של כל אדם ו/או לפרסם דברי הסתה, הונאה,
    //                 תרמית, לשון הרע ו/או כל מידע אחר שהינו שקרי, בלתי אמין או
    //                 שיש בו כדי לפגוע במזיד. <br /> 6.4 אי עמידה במגבלות אלו
    //                 עלולה להוביל למניעת גישת המשתמש לאתר ואף לחשוף אותו לאחריות
    //                 אזרחית ו/או פלילית, על-פי כל דין.`{" "}
    //             </p>
    //             <h2 className="text-2xl">קניין רוחני</h2>
    //             <p>
    //                 {" "}
    //                 1. החברה הינה בעלת זכויות הקניין הרוחני באתר. החברה הינה
    //                 בעלת זכויות הקנין הרוחני ו/או קיבלה הרשאה לשמוש בתכנים
    //                 שבאתר. <br /> 2. אין להעתיק, לפרסם, לשכפל, לשנות, לעבד,
    //                 למכור, להציג בפומבי, בשלמות ו/או כל חלק מהאתר ו/או מהתכנים
    //                 המופיעים בן, ללא קבלת הסכמה מראש ובכתב של החברה. <br /> 3.
    //                 ככל שבאתר מופיעים קישורים לאתרי אינטרנט. החברה לא תהיה
    //                 אחראית לתכנים המופיעים באותם אתרים מקושרים ולא תשא באחריות
    //                 כלשהיא לכל נזק ישיר או עקיף אשר ייגרם למשתמש כתוצאה ביקור
    //                 באתרים אלה. בנוסף, החברה איננה מתחייבת כי כל הקישורים יהיו
    //                 תקינים.{" "}
    //             </p>
    //             <h2 className="text-2xl">מדיניות הגנת פרטיות</h2>
    //             <p>
    //                 {" "}
    //                 הגישה&nbsp; לאתר והשימוש בו כפופים{" "}
    //                 <a
    //                     href="https://superpashut.mabasir.co.il/general/privacy"
    //                     target="_blank"
    //                 >
    //                     למדיניות הפרטיות
    //                 </a>{" "}
    //                 ולתנאי השימוש, המהווים יחד מסמך משפטי מחייב בין המשתמש לבין
    //                 החברה. השימוש בשירותים ייחשב להסכמה מצד המשתמש לכל התנאים,
    //                 ההתניות וההודעות.{" "}
    //             </p>
    //             <h2 className="text-2xl">שיפוט</h2>
    //             <p>
    //                 {" "}
    //                 הדין החל על כל הפעולות באתר הוא הדין של מדינת ישראל בלבד,
    //                 וכל מחלוקת&nbsp; ביחס לקיום הוראותיו של הסכם זה, תדון בבתי
    //                 המשפט במחוז מרכז בלבד.{" "}
    //             </p>
    //         </div>
    //     </div>
    // );
}
