import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment, useState } from "react";

export default function SelectCategoryPopup({
    show,
    button,
    categoryOptions,
    onSelect,
    disabled,
}: {
    button: JSX.Element;
    categoryOptions: Array<{ id: string; title: string; icon: string }>;
    onSelect: ({ categoryId }: { categoryId: string }) => void;
    disabled?: boolean;
    show?: boolean;
}) {
    let [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    if (!show) return null;

    return (
        <>
            <div className="">
                <button type="button" disabled={disabled} onClick={openModal}>
                    {button}
                </button>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50 font-heebo"
                    onClose={closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-slate-900/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto" dir="rtl">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full relative max-w-md transform overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="div"
                                        className="flex justify-between sticky top-0 items-center w-full py-3"
                                    >
                                        <h3 className="text-2xl font-medium leading-6 text-gray-900">
                                            בחירת קטגוריה
                                        </h3>
                                        <button onClick={closeModal}>
                                            <XIcon className="w-7 h-7" />
                                        </button>
                                    </Dialog.Title>
                                    <ul className="flex flex-col gap-2 dividey max-h-[60vh] overflow-y-auto">
                                        {categoryOptions.map((category) =>
                                            category.icon ? (
                                                <li key={category.id}>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            onSelect({
                                                                categoryId:
                                                                    category.id,
                                                            });
                                                            closeModal();
                                                        }}
                                                        className="flex text-xl font-medium items-center gap-3 w-full py-2"
                                                    >
                                                        {category.icon}
                                                        <span>
                                                            {category.title}
                                                        </span>
                                                    </button>
                                                </li>
                                            ) : null
                                        )}
                                    </ul>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
