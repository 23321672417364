import { ArrowLeftIcon, CheckIcon, PencilIcon } from "@heroicons/react/outline";
import TrashIcon from "components/icons/TrashIcon";
import Link from "components/Link";
import LoadingComponents from "components/Loader";
import { SideMenuDrawer } from "components/Navbar";
import { JoinToPremiumButton } from "components/PremiumWall";
import { deleteListFromUser, updateList, updateUser } from "db-utils";
import { Timestamp } from "firebase/firestore";
import useAlert from "hooks/useAlert";
import useConfirm from "hooks/useConfirm";
import useListsData from "hooks/useListsData";
import useUser from "hooks/useUser";
import { useEffect, useRef, useState } from "react";
import { LIST_TYPES, ListData } from "types";
import { isPremium } from "utils";

export default function ListsPage() {
    const { listsData, loading, error } = useListsData();
    const { user, loading: userLoading } = useUser();

    if (loading || userLoading) return <LoadingComponents fullPage />;

    if (!user || !listsData) return null;

    if (error) {
        return (
            <div className="text-center text-2xl text-gray-700 grid place-content-center">
                קרתה תקלה, יש לנסות שנית מאוחר יותר
            </div>
        );
    }

    const isPremiumUser = isPremium({ type: user?.userData?.accountType });

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-start gap-2 text-center w-full">
                <div className="">
                    <SideMenuDrawer />
                </div>
                <h1 className="text-center text-2xl font-medium text-gray-700">
                    הרשימות שלי
                </h1>
            </div>
            <ul className="w-full flex divide-y divide-slate-50 flex-col mt-6 px-2 pb-5">
                {listsData.map((listData, index) =>
                    !listData ? null : (
                        <li className="w-full" key={listData.listId}>
                            <ListsItem
                                listId={listData.listId}
                                listData={listData}
                                index={index}
                                isSingleList={listsData?.length === 1}
                            />
                        </li>
                    )
                )}
            </ul>
            {isPremiumUser ? null : (
                <div className="text-center text-xl my-5 w-full h-44 border-2 border-green-500 rounded-lg p-6 text-gray-700 flex flex-col justify-between items-center">
                    <div>
                        משתמשי <span className="font-medium">Plus</span> יכולים
                        לפתוח ולערוך עוד רשימות
                    </div>
                    <JoinToPremiumButton userId={user.userId} />
                </div>
            )}
        </div>
    );
}

const ListsItem = ({
    listId,
    listData,
    index,
    isSingleList,
}: {
    listId: string;
    listData: ListData;
    index: number;
    isSingleList: boolean;
}) => {
    const { name, type } = listData;
    const { user, loading } = useUser();
    const [newName, setNewName] = useState(name);
    const [isEditing, setIsEditing] = useState(false);
    const { isConfirmed } = useConfirm();
    const { isAlerted } = useAlert();

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus();
        }
    }, [isEditing]);

    if (loading) return <LoadingComponents />;

    if (!user)
        return (
            <div className="text-center text-2xl text-gray-700 grid place-content-center">
                קרתה תקלה, יש להיכנס דרך הקישור שקיבלתם בוואטסאפ
            </div>
        );

    const handleDelete = async () => {
        const confirm = await isConfirmed(
            "בטוח למחוק את הרשימה? כל המידע ימחק לכל המשתתפים"
        );
        if (confirm) {
            deleteListFromUser({ listId, userId: user.userId });
        }
    };

    const handleNameSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const listName = newName.trim();
        if (!listName) {
            await isAlerted("שם הרשימה לא יכול להיות ריק");
            // alert("שם הרשימה לא יכול להיות ריק");
            return;
        }

        if (listName.length > 24) {
            await isAlerted("שם הרשימה ארוך מדי");
            // alert("שם הרשימה ארוך מדי");
            return;
        }

        if (listName !== name) {
            await updateList({
                listId,
                updates: { name: listName },
            });
            await updateUser({
                userId: user.userId,
                updates: { lastListNameChange: Timestamp.now() }, // this to rerender the lists
            });
        }
        setIsEditing(false);
    };

    const isPremiumUser = isPremium({ type: user.userData?.accountType });

    const isPrimaryList = type === LIST_TYPES.primary;

    return (
        <div className="flex items-center justify-between w-full py-4">
            <div className="text-2xl flex gap-1 items-center">
                {index + 1}.{" "}
                {isEditing ? (
                    <input
                        ref={inputRef}
                        className="border-b border-slate-300 px-2 focus:outline-none w-60"
                        value={newName}
                        placeholder="שם הרשימה"
                        onChange={(e) => setNewName(e.target.value)}
                    />
                ) : (
                    <Link
                        to={`/list/${listId}/`}
                        className="text-start text-gray-700  block underline decoration-1"
                    >
                        {name}
                    </Link>
                )}
            </div>
            {isPremiumUser ? (
                <div className="flex gap-3 items-center">
                    {isEditing ? (
                        <button
                            onClick={handleNameSubmit}
                            disabled={isPrimaryList}
                            className="group"
                        >
                            <CheckIcon className="h-6 w-6 text-green-500 group-disabled:text-slate-200" />
                        </button>
                    ) : (
                        <button
                            disabled={isPrimaryList}
                            onClick={() => setIsEditing((prev) => !prev)}
                            className="group"
                        >
                            <PencilIcon
                                className="h-6 w-6 text-slate-600 group-disabled:text-slate-200"
                                aria-hidden="true"
                            />
                        </button>
                    )}
                    <button
                        disabled={isPrimaryList}
                        onClick={handleDelete}
                        className="group"
                    >
                        <TrashIcon
                            className="h-6 w-6 text-red-400 group-disabled:text-slate-200"
                            aria-hidden="true"
                        />
                    </button>
                </div>
            ) : isSingleList ? (
                <ArrowLeftIcon className="h-6 w-6 text-slate-700" />
            ) : null}
        </div>
    );
};
